import axios from "axios";
import helper from "./helper";

let host = process.env.VUE_APP_API_URL;
let api_key = process.env.VUE_APP_API_KEY;
const STRAPI_API_URL = "https://cms.webmanza.com/api";
const STRAPI_API_KEY = process.env.VUE_APP_STRAPI_TOKEN;

function apiData(object = {}) {
  let application_version = `browseit_v:${helper.app_version()}`;
  let language = "en";
  let source = "web";
  return {
    ...object,
    app_name: application_version,
    lang: language,
    source
  };
}

function api_token_validation(access_token, valid_till, refresh_token) {
  // if access token empty then enter into this block
  let path = "";
  let payload = {};
  let is_token_valid = true;
  if (!helper.data_size(access_token)) {
    path = "api/v2/get-access-token";
    is_token_valid = false;
  } else {
    // check access token validity
    let current_time_in_s = helper.current_time_in_ms() / 1000;
    //console.log("current_time_in_s:", current_time_in_s);
    if (current_time_in_s > valid_till) {
      // token expired, renew token
      is_token_valid = false;
      path = "api/v2/renew-access-token";
      payload = {
        refresh_token: refresh_token
      };
    }
  }
  return { is_token_valid, path, payload };
}

async function rpc_connector(path, request_data = {}, token_type = "bearer") {
  let config = {},
    token = "";
  if (token_type === "bearer") {
    let token_details = helper.get_localstorage_user_data("userInfo");
    let { access_token, valid_till, refresh_token } = token_details;
    token = access_token;
    // console.log("token_pre:", token);
    // check token existence and validation
    let token_validation_response = api_token_validation(
      access_token,
      valid_till,
      refresh_token
    );
    //console.log('token_validation_response:', token_validation_response);
    if (!token_validation_response.is_token_valid) {
      let { path, payload } = token_validation_response;
      // caution: don't set third parameter=bearer(infinite loop)
      let jwt_token_response = await rpc_connector(path, payload, "api_key");
      // console.log("jwt_token_response:", jwt_token_response.data);
      let { status_code } = jwt_token_response.data;
      if (status_code === 200) {
        let {
          access_token,
          refresh_token,
          access_token_expiry_time_in_sec
        } = jwt_token_response.data;
        token = access_token;
        // console.log("token_next:", token);
        let userInfo = {
          ...token_details,
          access_token: access_token,
          refresh_token: refresh_token,
          valid_till: access_token_expiry_time_in_sec
        };
        helper.set_localstorage_user_data(userInfo);
      } else {
        helper.remove_user_data_from_localstorage("userInfo");
        return jwt_token_response;
      }
    }
    config = {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`
      }
    };
  } else {
    config = {
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
        api_key: api_key
      }
    };
  }
  // console.log("config: ", config);
  const url = `${host}/${path}`;
  let payload = apiData(request_data);
  return await axios.post(url, payload, config);
}

async function get_ip_region(url, ip) {
  url = `${url}/${ip}`;
  return await axios.get(url);
}

async function send_token_server(url, request_data) {
  return await axios.post(url, request_data);
}

async function getNews() {
  const url = STRAPI_API_URL;
  return await axios.get(`${url}/browseit-posts?populate=*`, {
    headers: {
      Authorization: `Bearer ${STRAPI_API_KEY}`
    }
  });
}

async function getDeals() {
  const url = STRAPI_API_URL;
  return await axios.get(`${url}/browseit-deals?populate=*`, {
    headers: {
      Authorization: `Bearer ${STRAPI_API_KEY}`
    }
  });
}

async function getPopup() {
  const url = STRAPI_API_URL;
  return await axios.get(`${url}/browseit-popups?populate=*`, {
    headers: {
      Authorization: `Bearer ${STRAPI_API_KEY}`
    }
  });
}

async function getFeaturedProducts() {
  const url = STRAPI_API_URL;
  return await axios.get(`${url}/browseit-featured-products?populate=*`, {
    headers: {
      Authorization: `Bearer ${STRAPI_API_KEY}`
    }
  });
}

export default {
  rpc_connector,
  get_ip_region,
  send_token_server,
  getNews,
  getDeals,
  getPopup,
  getFeaturedProducts
};
