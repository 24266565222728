const publicIp = require("public-ip");
import $ from "jquery";
import appInfo from "../../package";

/*
 * 600 = refresh token empty
 * 602 = refresh token expired
 * 604 = api key empty
 * 606 = api key invalid
 * 608 = access token empty
 * 610 = access token expired
 */

function get_localstorage_user_data(key = "") {
  let userInfo = localStorage.getItem(key);
  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    return {};
  }
}

function set_localstorage_user_data(userInfo = {}) {
  localStorage.setItem("userInfo", JSON.stringify(userInfo));
}

function remove_user_data_from_localstorage(key = "") {
  localStorage.removeItem(key);
}

function clear_storage() {
  localStorage.clear();
}

/*
 * find out object length
 */

function object_data_size(data = {}) {
  return Object.keys(data).length;
}

/*
 * find out array length
 */

function array_data_size(data = []) {
  return data.length;
}

function apiStatusCheck(statusCode, statusSet = [600, 602, 608, 610]) {
  return statusSet.includes(statusCode);
}

function convert_to_number(data) {
  return Number(data);
}

function convert_to_string(data) {
  return data.toString();
}

async function get_ipv4() {
  return await publicIp.v4();
}

async function get_public_ip() {
  let ip = await get_ipv4();
  return ip;
}

function app_version() {
  return appInfo.version;
}

function show_modal() {
  $(".model-main").addClass("model-open");
}

function close_modal() {
  $(".model-main").removeClass("model-open");
}

function slick_slider_configuration(
  slides_to_show = 1,
  slides_to_scroll = 1,
  arrows = false,
  autoplay = true,
  autoplay_speed = 3000,
  rows = 1,
  slides_per_row = 1,
  dots = false
) {
  return {
    slidesToShow: slides_to_show,
    slidesToScroll: slides_to_scroll,
    arrows: arrows,
    autoplay: autoplay,
    autoplaySpeed: autoplay_speed,
    rows: rows,
    slidesPerRow: slides_per_row,
    dots: dots
  };
}

function current_time_in_ms() {
  return Date.now();
}

export default {
  get_localstorage_user_data,
  set_localstorage_user_data,
  remove_user_data_from_localstorage,
  data_size: array_data_size,
  object_data_size,
  convert_to_number,
  convert_to_string,
  clear_storage,
  get_public_ip,
  app_version,
  apiStatusCheck,
  show_modal,
  close_modal,
  slick_slider_configuration,
  current_time_in_ms
};
