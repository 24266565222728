import Header from "@/components/Header";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      header: Header,
      default: () => import(/* webpackChunkName: "Home" */ "../views/NewHome")
    },
    meta: {
      title: "Home | Browseit"
    }
  },
  {
    path: "/search",
    name: "Search",
    components: {
      header: Header,
      default: () => import(/* webpackChunkName: "Search" */ "../views/Search")
    },
    meta: {
      title: "Search | Browseit"
    }
  },
  {
    path: "/news",
    name: "News",
    components: {
      header: Header,
      default: () =>
        import(/* webpackChunkName: "Search" */ "../views/NewsList")
    },
    meta: {
      title: "News | Browseit"
    }
  },
  // {
  //   path: "/news-details/:category_slug/:news_slug",
  //   name: "NewsDetails",
  //   components: {
  //     header: Header,
  //     default: () =>
  //       import(/* webpackChunkName: "NewsList" */ "../views/NewsList")
  //   },
  //   meta: {
  //     title: "NewsDetails | Browseit"
  //   }
  // },
  // {
  //   path: "/game",
  //   name: "Game",
  //   components: {
  //     header: Header,
  //     default: () =>
  //       import(/* webpackChunkName: "GameList" */ "../views/GameList")
  //   },
  //   meta: {
  //     title: "Game | Browseit"
  //   }
  // },
  {
    path: "/game",
    name: "Game",
    beforeEnter() {
      window.location.href = "https://symphony.mudgames.in"; // Redirect to external URL
    },
    meta: {
      title: "Game | Browseit"
    }
  },
  {
    path: "/ecommerce",
    name: "Ecommerce",
    components: {
      header: Header,
      default: () =>
        import(/* webpackChunkName: "Gadget Page" */ "../views/GadgetsPage")
    },
    meta: {
      title: "E-commerce | Browseit"
    }
  },
  {
    path: "/video",
    name: "Video",
    components: {
      header: Header,
      default: () =>
        import(/* webpackChunkName: "VideoList" */ "../views/VideoList")
    },
    meta: {
      title: "Video | Browseit"
    }
  },
  {
    path: "/watch",
    name: "VideoPlay",
    components: {
      header: Header,
      default: () =>
        import(/* webpackChunkName: "VideoPlay" */ "../views/VideoPlay")
    },
    meta: {
      title: "Video Play | Browseit"
    }
  },
  // {
  //   path: "/jobs-details/:category/:slug",
  //   name: "JobsDetails",
  //   components: {
  //     header: Header,
  //     default: () =>
  //       import(/* webpackChunkName: "JobDetails" */ "../views/JobDetails")
  //   },
  //   meta: {
  //     title: "JobDetails | Browseit"
  //   }
  // },
  {
    path: "/success",
    name: "Success",
    components: {
      header: Header,
      default: () =>
        import(/* webpackChunkName: "Success" */ "../views/Success")
    },
    meta: {
      title: "Success | Browseit"
    }
  },
  {
    path: "/fail",
    name: "Fail",
    components: {
      header: Header,
      default: () => import(/* webpackChunkName: "Fail" */ "../views/Fail")
    },
    meta: {
      title: "Fail | Browseit"
    }
  },
  {
    path: "*",
    redirect: "/"
  }
];

const router = new VueRouter({
  mode: "hash", //"history",
  base: process.env.BASE_URL,
  routes
});

export default router;
