<template>
  <header class="header">
    <nav>
      <ul class="main-menu">
        <li v-for="(menu, index) in header_menu" :key="index">
          <router-link
            :to="{ name: menu.state }"
            tag="a"
            :class="{ active: menu.state === current_state }"
          >
            <img
              :src="image_path + menu.icon_name"
              :alt="menu.state + '-icon'"
            />
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "Header",
  data: () => {
    return {
      isActive: 0,
      image_path: "/assets/images/",
      current_state: "",
      header_menu: [
        {
          icon_name: "home.svg",
          state: "Home"
        },
        {
          icon_name: "video-game.svg",
          state: "Game"
        },
        {
          icon_name: "trolley.svg",
          state: "Ecommerce"
        },
        {
          icon_name: "play-button.svg",
          state: "Video"
        }
      ]
    };
  },
  created() {
    this.current_state = this.$route.name;
  }
};
</script>

<style scoped></style>
