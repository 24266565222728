import helper from "@/api/helper";
import rpc from "@/api/rpc";
import Vue from "vue";
import { Plugin } from "vue-fragment";
import App from "./App.vue";
import router from "./router";

Vue.use(Plugin);

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
new Vue({
  data: {
    apiStatusCode: 0
  },
  watch: {
    async apiStatusCode(val) {
      let path = "";
      let payload = {},
        token_details = {};
      if ([600, 602].includes(val)) {
        // refresh token expired/empty
        path = "api/v2/get-access-token";
      } else if ([608, 610].includes(val)) {
        // access token expired/empty
        path = "api/v2/renew-access-token";
        token_details = helper.get_localstorage_user_data("userInfo");
        let { refresh_token } = token_details;
        payload = {
          refresh_token: refresh_token
        };
      }
      let token_response = await rpc.rpc_connector(path, payload, "api_key");
      console.log("token_response:", token_response.data);
      let { status_code } = token_response.data;
      if (status_code === 200) {
        let {
          access_token,
          refresh_token,
          access_token_expiry_time_in_sec
        } = token_response.data;
        let userInfo = {
          ...token_details,
          access_token: access_token,
          refresh_token: refresh_token,
          valid_till: access_token_expiry_time_in_sec
        };
        helper.set_localstorage_user_data(userInfo);
        //this.$root.userInfo = userInfo;
        return location.reload();
      } else {
        helper.remove_user_data_from_localstorage("userInfo");
        //this.$root.userInfo = {};
        return true;
      }
    }
  },
  router,
  render: h => h(App)
}).$mount("#app");
