<template>
  <div id="app" class="phone">
    <div class="wrapper">
      <router-view name="header" :key="$route.name" />
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>
<script>
import helper from "./api/helper";
import rpc from "./api/rpc";
import { get_fcm_token } from "./service/firebase";

export default {
  name: "App",
  mounted() {
    // this.get_registration_token();
    // this.showNotification();
  },
  methods: {
    async showNotification() {
      console.log("🔔 show notification called");
      try {
        if ("Notification" in window) {
          let permission = await Notification.requestPermission();

          if (permission === "granted") {
            navigator.serviceWorker.ready.then(registration => {
              registration.showNotification("Hello from Browseit");
            });
            try {
              new Notification("Hello from Browseit");
            } catch (error) {
              return console.log("error while sending notification");
            }
          }
        }
      } catch (error) {
        console.error("Error requesting notification permission:", error);
      }
    },
    async get_registration_token() {
      try {
        if (!("Notification" in window)) {
          console.log("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
          //  Let's check whether notification permissions have already been granted
          console.log("already notification permissions granted");
        } else if (Notification.permission !== "denied") {
          // Otherwise, we need to ask the user for permission
          let permission = await Notification.requestPermission();
          if (permission === "granted") {
            let fcm_token_response = await get_fcm_token();
            //console.log("fcm_token_response :", fcm_token_response);
            if (fcm_token_response.status) {
              console.log("Notification permission granted.");
              this.send_token_server(fcm_token_response.fcm_token);
            }
          } else {
            console.log("notification permission denied ");
          }
        } else {
          console.log("notification permissions already blocked :");
        }
      } catch (e) {
        console.log("error occur in get registration token:", e);
      }
    },
    send_token_server(token = "") {
      let application_version = helper.app_version();
      const fcm_token_api_url = process.env.VUE_APP_FCM_TOKEN_SAVE_URL;
      let token_data = {
        app_name: `browseit_v:${application_version}`,
        device_mac: "",
        device_imei: "",
        android_ssaid: "",
        fcm_token: token,
        tList: "",
        latlng: ""
      };
      rpc
        .send_token_server(fcm_token_api_url, token_data)
        .then(response => {
          console.log("FCM_token_response:", response.data);
          return true;
        })
        .catch(error => {
          console.log("FCM_token_response:", error);
          return true;
        });
      return true;
    }
  }
};
</script>
